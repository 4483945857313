import React from "react"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO"
import "./index.scss"
import Desc from "../../../components/Desc"
import Title from "../../../components/Title"
import {graphql} from 'gatsby'
import injectComponents from "../../../helpers/injectReactComponents"
import Footer from "../../../components/Footer";

function MiniParagraph({children}) {
  let collection = React.Children.toArray(children);
  let [title] = collection.filter(child => (child.type || '').toString().includes('h')).map(child => child.props.children);
  let [desc] = collection.filter(child => child.type === 'p').map(child => child.props.children);

  return (
  <div className="MiniParagraph" >
    <Title.Large>{title}</Title.Large>
    <Desc.Normal>{desc}</Desc.Normal>
  </div>)
}

let componentsReplace = {section: MiniParagraph};

const StudioPhilosophy = ({data}) => {
  let {template} = data.cms.philosophies[0];

  return (
  <Layout className='StudioPhilosophy' Footer={<Footer/>}>
    <SEO title="Contact" keywords={[]}/>

    {injectComponents(template, componentsReplace)}

  </Layout>
)};

export const query = graphql`
{
  cms {
	philosophies {
      template
      extractImages {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 90) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
}
`;

export default StudioPhilosophy
